import React from 'react'

export default function Footer(props) {
  return (
    <div className={`footer--container ${props.darkMode ? 'dark' : ''}`}>
      <a
        href="https://github.com/RandyPol"
        target="_blank"
        className="footer--github__link"
        rel="noreferrer"
      >
        <span>
          <ion-icon name="logo-github"></ion-icon>
        </span>
        <span>GitHub</span>
      </a>

      {/* <div className="footer--pages__popup" onClick={props.openPopup}>
        <span>
          <ion-icon name="build-outline"></ion-icon>
        </span>
        <span>Projects</span>
      </div> */}
    </div>
  )
}
