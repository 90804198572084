import React from 'react'

export default function Main(props) {
  return (
    <main className={`main--container ${props.darkMode ? 'dark' : ''}`}>
      <div>
        <h3 className="main--title">Hello All,</h3>
        <p className="main--text">
          My name is Randy Polanco, and I'm a software
          developer. I graduated from the University of Southern California with
          a degree in economics with a concentration in behavioral economics.
        </p>
        <p className="main--text">
          {' '}
          I have been working as a product manager for the last five years.
          After the pandemic, I started moving towards a more technical role to
          satisfy my thirst for tech knowledge and curiosity. I have built
          numerous software projects using the MERN stack, from static pages to
          full-stack web applications.
        </p>
        <p className="main--text">
          I'm excited to continue to learn and grow as a software developer.I
          look forward to joining a tech team to contribute with my past work
          experience and as a contributing tech member.
        </p>
      </div>

      <p className="main--prompt__text">Check out my code</p>
    </main>
  )
}
