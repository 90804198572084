import React from 'react'
import DisplayPic from '../images/IMG_6689.jpeg'

export default function Info(props) {
  return (
    <nav className={`info--container ${props.darkMode ? 'dark' : ''}`}>
      <img src={DisplayPic} alt="Display" className="info--picture" />

      <div className="info--theme__icon" onClick={props.toggleDarkMode}>
        {/* <ion-icon name="contrast-outline"></ion-icon> */}
        <ion-icon name="flashlight-outline"></ion-icon>
        {/* <ion-icon name="bulb-outline"></ion-icon> */}
      </div>

      <h1>Randy Polanco</h1>

      <h4>Software Developer</h4>

      <a
        href="mailto: randypolure@gmail.com"
        target="_blank"
        className="info--email__link"
        rel="noreferrer"
      >
        <span>
          <ion-icon name="mail"></ion-icon>
        </span>
        <span>Email</span>
      </a>
      <a
        href="https://www.linkedin.com/in/randypol/"
        target="_blank"
        className="info--linkedin__link"
        rel="noreferrer"
      >
        <span>
          <ion-icon name="logo-linkedin"></ion-icon>
        </span>
        <span>LinkedIn</span>
      </a>
      <a
        href="https://drive.google.com/file/d/1zU_-ytRlKa8EYeKW1M8ZvpdF8N3ou5-w/view?usp=sharing"
        target="_blank"
        className="info--resume__link"
        rel="noreferrer"
      >
        <span>
          <ion-icon name="briefcase"></ion-icon>
        </span>
        <span>Resume</span>
      </a>
    </nav>
  )
}
